:--desktop-device .stepsContent {
  margin-top: 16px;
  padding: 10px;
}

:--mobile-device .stepsContent {
  padding: 5px;
}

.stepsContent {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
}

.stepsAction {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.stepsActionMiddle {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}

.saveButton {
  background: #50ff41;
  border-color: #50ff41;

  &:hover,
  &:active,
  &:focus {
    background: #50ff41;
    border-color: #50ff41;
  }
}

.captchaButton {
  color: #fff !important;
  background: rgb(103, 181, 255);
  background: linear-gradient(
    90deg,
    rgba(103, 181, 255, 1) 0%,
    rgba(84, 172, 254, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  );
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: rgb(103, 181, 255);
    background: linear-gradient(
      90deg,
      rgba(103, 181, 255, 1) 10%,
      rgba(84, 172, 254, 1) 60%,
      rgba(0, 212, 255, 1) 100%
    );
  }
}

.editMode {
  margin-bottom:10px;
}