.stepsContent {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 80px;
}

.stepsAction {
  margin-top: 24px;
}

.space {
  padding: 5px;
}

.outSpace {
  margin: 5px;
}

.customStyle {
  border-radius: 4px;
  border: 0;
  overflow: hidden;
  & .ant-collapse-header {
    background: #deeffe;
    border-radius: 4px;
    border: 0;
    overflow: hidden;
  }
}

.addMore {
  background: #f3f9ff;
  border-radius: 5px;
  padding:10px;
  margin: 5px;
}